// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDspZZYWdXekL-cPxLQGEur7dNXXvl8jOU",
  authDomain: "supernewecommerceseo3.firebaseapp.com",
  projectId: "supernewecommerceseo3",
  storageBucket: "supernewecommerceseo3.appspot.com",
  messagingSenderId: "163064649222",
  appId: "1:163064649222:web:f78a47da290f21c33e9587"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;